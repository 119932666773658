.cm-s-ttcn .cm-quote { color: #090; }
.cm-s-ttcn .cm-negative { color: #d44; }
.cm-s-ttcn .cm-positive { color: #292; }
.cm-s-ttcn .cm-header, .cm-strong { font-weight: bold; }
.cm-s-ttcn .cm-em { font-style: italic; }
.cm-s-ttcn .cm-link { text-decoration: underline; }
.cm-s-ttcn .cm-strikethrough { text-decoration: line-through; }
.cm-s-ttcn .cm-header { color: #00f; font-weight: bold; }

.cm-s-ttcn .cm-atom { color: #219; }
.cm-s-ttcn .cm-attribute { color: #00c; }
.cm-s-ttcn .cm-bracket { color: #997; }
.cm-s-ttcn .cm-comment { color: #333333; }
.cm-s-ttcn .cm-def { color: #00f; }
.cm-s-ttcn .cm-em { font-style: italic; }
.cm-s-ttcn .cm-error { color: #f00; }
.cm-s-ttcn .cm-hr { color: #999; }
.cm-s-ttcn .cm-invalidchar { color: #f00; }
.cm-s-ttcn .cm-keyword { font-weight:bold; }
.cm-s-ttcn .cm-link { color: #00c; text-decoration: underline; }
.cm-s-ttcn .cm-meta { color: #555; }
.cm-s-ttcn .cm-negative { color: #d44; }
.cm-s-ttcn .cm-positive { color: #292; }
.cm-s-ttcn .cm-qualifier { color: #555; }
.cm-s-ttcn .cm-strikethrough { text-decoration: line-through; }
.cm-s-ttcn .cm-string { color: #006400; }
.cm-s-ttcn .cm-string-2 { color: #f50; }
.cm-s-ttcn .cm-strong { font-weight: bold; }
.cm-s-ttcn .cm-tag { color: #170; }
.cm-s-ttcn .cm-variable { color: #8B2252; }
.cm-s-ttcn .cm-variable-2 { color: #05a; }
.cm-s-ttcn .cm-variable-3, .cm-s-ttcn .cm-type { color: #085; }

.cm-s-ttcn .cm-invalidchar { color: #f00; }

/* ASN */
.cm-s-ttcn .cm-accessTypes,
.cm-s-ttcn .cm-compareTypes { color: #27408B; }
.cm-s-ttcn .cm-cmipVerbs { color: #8B2252; }
.cm-s-ttcn .cm-modifier { color:#D2691E; }
.cm-s-ttcn .cm-status { color:#8B4545; }
.cm-s-ttcn .cm-storage { color:#A020F0; }
.cm-s-ttcn .cm-tags { color:#006400; }

/* CFG */
.cm-s-ttcn .cm-externalCommands { color: #8B4545; font-weight:bold; }
.cm-s-ttcn .cm-fileNCtrlMaskOptions,
.cm-s-ttcn .cm-sectionTitle { color: #2E8B57; font-weight:bold; }

/* TTCN */
.cm-s-ttcn .cm-booleanConsts,
.cm-s-ttcn .cm-otherConsts,
.cm-s-ttcn .cm-verdictConsts { color: #006400; }
.cm-s-ttcn .cm-configOps,
.cm-s-ttcn .cm-functionOps,
.cm-s-ttcn .cm-portOps,
.cm-s-ttcn .cm-sutOps,
.cm-s-ttcn .cm-timerOps,
.cm-s-ttcn .cm-verdictOps { color: #0000FF; }
.cm-s-ttcn .cm-preprocessor,
.cm-s-ttcn .cm-templateMatch,
.cm-s-ttcn .cm-ttcn3Macros { color: #27408B; }
.cm-s-ttcn .cm-types { color: #A52A2A; font-weight:bold; }
.cm-s-ttcn .cm-visibilityModifiers { font-weight:bold; }
